
// https://github.com/thomaspark/bootswatch/blob/gh-pages/paper/_bootswatch.scss#L284-L453

.radio,
.radio-inline,
.checkbox,
.checkbox-inline {
  label {
    padding-left: 25px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin-left: -25px;
  }
}

input[type="radio"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: relative;
  margin-top: 6px;
  margin-right: 4px;
  vertical-align: top;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    @include transition(240ms);
  }

  &:before {
    position: absolute;
    left: 0;
    top: -3px;
    background-color: $brand-primary;
    @include scale(0);
  }

  &:after {
    position: relative;
    top: -3px;
    border: 2px solid $gray;
  }

  &:checked:before {
    @include scale(0.5);
  }

  &:disabled:checked:before {
    background-color: $gray-light;
  }

  &:checked:after {
    border-color: $brand-primary;
  }

  &:disabled:after,
  &:disabled:checked:after {
    border-color: $gray-light;
  }
}

input[type="checkbox"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: relative;
  border: none;
  margin-bottom: -4px;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus:after {
    border-color: $brand-primary;
  }

  &:after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: 5px;
    border: 2px solid $gray;
    border-radius: 2px;
    @include transition(240ms);
  }

  &:checked:before {
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    display: table;
    width: 6px;
    height: 12px;
    border: 2px solid #fff;
    border-top-width: 0;
    border-left-width: 0;
    @include rotate(45deg);
  }

  &:checked:after {
    background-color: $brand-primary;
    border-color: $brand-primary;
  }

  &:disabled:after {
    border-color: $gray-light;
  }

  &:disabled:checked:after {
    background-color: $gray-light;
    border-color: transparent;
  }
}

.has-warning {
  input:not([type=checkbox]),
  .form-control,
  input.form-control[readonly],
  input[type=text][readonly],
  [type=text].form-control[readonly],
  input:not([type=checkbox]):focus,
  .form-control:focus {
    border-bottom: none;
    @include box-shadow(inset 0 -2px 0 $brand-warning);
  }
}

.has-error {
  input:not([type=checkbox]),
  .form-control,
  input.form-control[readonly],
  input[type=text][readonly],
  [type=text].form-control[readonly],
  input:not([type=checkbox]):focus,
  .form-control:focus {
    border-bottom: none;
    @include box-shadow(inset 0 -2px 0 $brand-danger);
  }
}

.has-success {
  input:not([type=checkbox]),
  .form-control,
  input.form-control[readonly],
  input[type=text][readonly],
  [type=text].form-control[readonly],
  input:not([type=checkbox]):focus,
  .form-control:focus {
    border-bottom: none;
    @include box-shadow(inset 0 -2px 0 $brand-success);
  }
}
