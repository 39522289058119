$red: #FF4A00;
$red-light: #FF9266;
$orange: #f5662b;
$orange-light: #FF9F00;
$orange-lighter: #FFC566;
$green: #97ba4c;

.personal-health-score-label {
  background: #B1B1B1;
  border-radius: .5ex;
  padding: .3ex 1.2ex;
  margin-left: 2px;
  font-weight: 500;
  color: white;
  font-size: 12px;
}

.personal-health-score-label--A  { background-color: $green; }
.personal-health-score-label--B1 { background-color: $orange-lighter; }
.personal-health-score-label--B2 { background-color: $orange-light; }
.personal-health-score-label--C1 { background-color: $red-light; }
.personal-health-score-label--C2 { background-color: $red; }

.personal-health-score-bg--A  { background-color: alpha($green); }
.personal-health-score-bg--B1 { background-color: alpha($orange-lighter); }
.personal-health-score-bg--B2 { background-color: alpha($orange-light); }
.personal-health-score-bg--C1 { background-color: alpha($red-light); }
.personal-health-score-bg--C2 { background-color: alpha($red); }

.personal-health-score--A  { color: $green }
.personal-health-score--B1 { color: $orange-lighter; }
.personal-health-score--B2 { color: $orange-light; }
.personal-health-score--C1 { color: $red-light; }
.personal-health-score--C2 { color: $red; }
