
// Bootstrap

$font-family-sans-serif: 'Montserrat', sans-serif;
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';

$brand-primary: $paradisolight;

$navbar-inverse-bg: $ship;
$navbar-inverse-link-color: #f8f8f8;
$navbar-inverse-toggle-hover-bg: darken($navbar-inverse-bg, 10%);
$navbar-inverse-toggle-border-color: darken($navbar-inverse-bg, 10%);
$navbar-border-radius: 0;
$navbar-height: 60px;

$popover-border-color: #eaeaea;

$headings-font-weight: 400;