
.form-auth {
  margin: 0 auto;
  max-width: 400px;
  margin-bottom: 50px;

  input.email {
    margin-bottom: 10px
  }

  input.password {
    margin-bottom: 10px
  }
}
