
.comparison-detail {
  border-top: 1px solid #eee;
  &> .products-list {
    border-right: 1px solid #eee;
    padding: 20px;
    .searchbox {
      margin: 0 0px 0 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee;
    }
    ul {
      li {
        position: relative;
        a.remove-product {
          display: none;
          position: absolute;
          right: 0px;
          bottom: 5px;
        }
        &:hover {
          a.remove-product {
            display: block;
          }
        }
      }
    }
  }
  &> .nutrients {
    padding: 20px;
  }

  .x-axis,
  .y-axis {
    text {
      font-family: $font-family-sans-serif;
    }
    g.tick line,
    path.domain {
      stroke: #aaa;
      display: none;
    }
  }
  text.value,
  g.tick text {
    fill: #aaa;
    font-family: $font-family-sans-serif;
  }
  text.value {
    font-size: 13px;
  }
  .x-axis g.tick text {
    font-size: 12px;
  }
  .y-axis g.tick text {
    font-size: 13px;
  }
  .bar {
    fill: #ddd;
    &:hover {
      fill: darken(#ddd, 6%);
    }
  }
  .bar.owned {
    fill: $paradisolight;
    &:hover {
      fill: darken($paradisolight, 6%);
    }
  }

}
