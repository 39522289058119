
.loading {
  @extend .text-muted;
  &> div {
    font-size: 100;
    margin-left: 10px;
  }

  &.padded {
    margin: 50px;
  }
}

// from https://tobiasahlin.com/spinkit/
// project suggests copying CSS, and three bounce is not in default CSS (but is in demo)

.three-bounce > div {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #fff;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
  animation: bouncedelay 1.4s infinite ease-in-out both
}

.three-bounce .one {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s
}

.three-bounce .two {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1)
  }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0)
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1)
  }
}