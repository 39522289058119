
label,
th {
  font-weight: 500;
}

td {
  vertical-align: middle !important;
}

th small {
  font-weight: 200;
  @extend .text-muted;
}

.label {
  font-weight: normal;
}

b,
strong {
  font-weight: 500;
}

// https://github.com/thomaspark/bootswatch/blob/gh-pages/paper/_bootswatch.scss#L36-L61

.navbar {
  border: none;
  @include box-shadow(0 1px 2px rgba(0,0,0,.3));

  &-brand {
    font-size: 24px;
  }

  &-inverse {
    .navbar-form {

      input[type=text],
      input[type=password] {
        color: #fff;
        @include box-shadow(inset 0 -1px 0 $navbar-inverse-link-color);
        @include placeholder($navbar-inverse-link-color);

        &:focus {
          @include box-shadow(inset 0 -2px 0 #fff);
        }
      }
    }
  }
}

// https://github.com/thomaspark/bootswatch/blob/gh-pages/paper/_bootswatch.scss#L523

.Select-menu-outer,
.dropdown-menu {
  margin-top: 0;
  border: none;
  @include box-shadow(0 1px 4px rgba(0,0,0,.3));
}

