
ul.list-qmi {
  &>li {
    border-bottom: 1px solid #eee;
    padding: 10px 0;

    &:last-child {
      border-bottom: 0;
    }
  }
}
