
.searchbox {
  position: relative;

  .loading {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.navbar-form {
  .react-autosuggest__container {
    background: rgba(255,255,255,0.125);
  }

  .react-autosuggest__input {
    color: #fff;
    background: none;
    border: 0;
    box-shadow: none !important;
    outline: none;
    width: 380px !important;
    -webkit-appearance: none;
    &:focus {
      background: rgba(255,255,255,0.105);
    }
  }

  .react-autosuggest__suggestions-container--open {
    width: 380px;
  }
}

// Overrides
.react-autosuggest__container {
  border-radius: 3px;
}

.react-autosuggest__input {
  height: 34px;
  padding-left: 12px;
  @extend .form-control;
  width: 100%;
  font-family: $font-family-sans-serif;
  font-size: inherit;
}

.react-autosuggest__suggestions-container--open {
  font-family: $font-family-sans-serif;
  position: absolute;
  top: 33px;
  border: 1px solid #aaa;
  font-size: inherit;
}

.react-autosuggest__suggestion {
  border-bottom: 1px solid #eee;
}

.react-autosuggest__suggestion:last-child {
  border-bottom: 0;
}

@media screen and (max-width: $screen-sm-max) {
  .navbar-form .react-autosuggest__input {
    width: auto !important;
  }
}

@media screen and (max-width: $screen-xs-max) {
  .navbar-form .react-autosuggest__input {
    width: 100% !important;
  }
}

