
.barchart {
  .bar {
    fill: $paradisolight;
  }
  .bar:hover {
    cursor: pointer;
  }
  .axis--x path,
  .axis--y path {
    display: none;
  }
  .axis--x {
    text {
      font-family: $font-family-sans-serif;
    }
    g.tick line {
      stroke: #aaa;
    }
  }
  .axis--y {
    text {
      font-family: $font-family-sans-serif;
    }
    g.tick line,
    path.domain {
      stroke: #aaa;
    }
  }
  g.tick text {
    fill: #aaa;
    font-family: $font-family-sans-serif;
  }
}
