
.toolbar {
  height: 60px;
  &.active {
    background: lighten($brand-primary, 46%);
    padding: 15px;
  }
  h2 {
    margin: 0;
  }
  margin: 10px 0;
  &.affix {
    margin: 0;
    z-index: 2;
    box-shadow: 0 1px 1px #999;
  }
}
