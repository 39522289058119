
// General

section {
  margin-bottom: 30px;
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-bottom: 27px;
}

@media screen and (max-width: $screen-xs) {
  h1 {
    font-size: 28px;
  }
}

// Links

a:hover,
a:focus,
a:active {
  text-decoration: none;
}
a {
  cursor: pointer;
}

a.brand-danger {
  color: $brand-danger;
}

// Popover, Dropdown

.popover,
.dropdown-menu,
.popover-title {
  border-radius: 0;
}

// Table

table.sortable thead {
  th {
    &.sortable:hover {
      background: #f9f9f9;
      cursor: pointer;
    }

    &.sortable.active {
      position: relative;
      cursor: pointer;
      &.asc {
        &:after {
          @extend .glyphicon-extend;
          content: "\e253";
          position: absolute;
          padding-right: 20px;
          right: 0;
        }
      }

      &.desc {
        &:after {
          @extend .glyphicon-extend;
          content: "\e252";
          position: absolute;
          padding-right: 20px;
          right: 0;
        }
      }
    }
  }
}

.snackbar-container {
  font-family: $font-family-sans-serif;
}
