
// Navbar

.navbar {
  margin: 0;
}

.navbar-brand > img {
  height: 20px;
  display: inline;
}
